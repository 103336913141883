<template>
  <div>
    <v-data-table :headers="headers" :items="usuarios">
      <template v-slot:item.photoURL="{ item }">
        <img :src="item.photoURL" width="50" >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Usuarios-Admin",
  data() {
    return {
      usuarios: [],
      headers: [
        { text: "Nombre", value: "displayName" },
        { text: "Email", value: "email" },
        { text: "Teléfono", value: "telefono" },
        { text: "Foto", value: "photoURL" },
      ],
    };
  },

  created() {
    firebase
      .firestore()
      .collection("usuarios")
      .onSnapshot((snapshot) => {
        const usuarios = [];
        snapshot.forEach((doc) => {
          usuarios.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        this.usuarios = usuarios.map((b) => b.data);
      });
  },
};
</script>

<style></style>
