<template>
  <div>
    <v-data-table :headers="headers" :items="cotizaciones">
      <template v-slot:item.timestamp="{ item }">
        <p class="text-capitalize">
          {{ formatDate(item.timestamp.toDate()) }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";

export default {
  name: "Cotizaciones-Admin",
  data() {
    return {
      cotizaciones: [],
      headers: [
        { text: "¿Cuándo fue realizada?", value: "timestamp" },
        { text: "Destino", value: "destino" },
        { text: "Aeronave", value: "aeronave" },
        { text: "Pasajeros", value: "pasajeros" },
        { text: "Mensaje", value: "mensaje" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "phone" },
        { text: "Fecha", value: "fecha" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .locale("es")
        .calendar();
    },
  },
  created() {
    firebase
      .firestore()
      .collection("cotizaciones")
      .onSnapshot((snapshot) => {
        const cotizaciones = [];
        snapshot.forEach((doc) => {
          cotizaciones.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        this.cotizaciones = cotizaciones.map((b) => b.data);
      });
  },
};
</script>

<style></style>
