<template>
  <v-dialog v-model="dialogoListaPasajeros">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click="abierto = true">
        <slot> </slot>
      </div>
    </template>
    <v-sheet class="py-5">
      <v-data-table :headers="headers" :items="pasajeros" dense>
        <template v-slot:item.rut="{ item }">
          <p v-if="item.documento === 'RUT'">
            {{ item.rut }}
          </p>
          <p v-else>{{ item.pasaporte }}</p>
        </template>
        <template v-slot:item.equipaje="{ item }">
          <ul>
            <li v-if="item.equipaje.mano">
              {{ item.equipaje.mano ? "Mano" : "" }}
            </li>
            <li v-if="item.equipaje.cabina">
              {{ item.equipaje.cabina ? "Cabina" : "" }}
            </li>
            <li v-if="item.equipaje.extra">
              {{ item.equipaje.extra ? "Extra" : "" }}
            </li>
          </ul>
        </template>
        <template v-slot:item.salud="{ item }">
          <ul v-if="item.salud">
            <li v-if="item.salud.sintomas">
              {{ item.salud.sintomas ? "Presenta sintomas" : "" }}
            </li>
            <li v-if="item.salud.contactoEstrecho">
              {{ item.salud.contactoEstrecho ? "Contacto estrecho" : "" }}
            </li>
            <li v-if="item.salud.pcrPositivo">
              {{ item.salud.pcrPositivo ? "PCR positivo 2 semanas" : "" }}
            </li>
            <li v-if="item.salud.haSalidoDelPais">
              {{ item.salud.haSalidoDelPais ? "Ha salido del país" : "" }}
            </li>
          </ul>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-btn @click="dialogoListaPasajeros = false" width="80%" rounded dark
          >Cerrar</v-btn
        >
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "listaPasajeros",
  created() {},
  data() {
    return {
      dialogoListaPasajeros: false,
      headers: [
        { text: "Nombre", value: "nombre", align: "start", divider: true },
        { text: "Documento", value: "documento", divider: true },
        { text: "N° doc.", value: "rut", divider: true },
        { text: "Pais", value: "pais", divider: true },
        { text: "Telefono", value: "telefono", divider: true },
        { text: "Email", value: "email", divider: true },
        { text: "Check-in", value: "telefono", divider: true },
        {
          text: "Contacto emergencia",
          value: "emergencia.nombre",
          divider: true,
        },
        { text: "Telefono", value: "emergencia.telefono", divider: true },
        {
          text: "Equipaje (Mano, Cabina, Extra)",
          value: "equipaje",
          divider: true,
        },
        {
          text: "Declaración covid",
          value: "salud",
          divider: true,
        },
      ],
    };
  },
  props: {
    checkIns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    pasajeros() {
      let listaPasajeros = [];
      let dataCheckIn = this.checkIns.map((v) =>
        v.data.forEach((p) => {
          listaPasajeros.push(p);
        })
      );

      return listaPasajeros;
    },
  },
  methods: {
    equipaje(equipaje) {
      let filteredObj = Object.keys(equipaje).reduce((p, c) => {
        if (equipaje[c]) p[c] = equipaje[c];
        return p;
      }, {});

      return filteredObj;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: initial;
}
</style>