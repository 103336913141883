<template>
  <div>
    <v-data-table :headers="headers" :items="busquedas">
      <template v-slot:item.timestamp="{ item }">
        <p class="text-capitalize">
          {{ formatDate(item.timestamp.toDate()) }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment"

export default {
  name: "Busquedas-Admin",
  data() {
    return {
      busquedas: [],
      headers: [
        { text: "¿Cuándo fue realizada?", value: "timestamp" },
        { text: "Viaje", value: "tipoDeViaje" },
        { text: "Origen", value: "origen" },
        { text: "Destino", value: "destino" },
        { text: "Aeronave", value: "aeronave.ida" },
        { text: "Pasajeros", value: "pasajeros" },
        { text: "Fecha", value: "fechas.ida" },
        { text: "Usuario", value: "usuario" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .locale("es")
        .calendar();
    },
  },
  created() {
    firebase
      .firestore()
      .collection("busquedas")
      .onSnapshot((snapshot) => {
        const busquedas = [];
        snapshot.forEach((doc) => {
          busquedas.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        this.busquedas = busquedas.map((b) => b.data);
      });
  },
};
</script>

<style></style>
