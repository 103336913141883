var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"label":"Busqueda de experiencias por destino","color":"deep-purple"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('h3',[_vm._v("Experiencias")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","items":_vm.experienciasFiltradas,"sort-by":['data.timestamp'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.data.checkIn",fn:function(ref){
var item = ref.item;
return [(item.data.checkIn)?_c('ListaPasajeros',{attrs:{"checkIns":item.data.checkIn}},[_c('v-btn',{attrs:{"color":_vm.colors.mainPurple,"dark":"","small":"","rounded":""}},[_vm._v("Ver datos")])],1):_c('p',[_vm._v("No hay pasajeros")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editExperiencia(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.copyExperiencia(item)}}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.data.timestamp",fn:function(ref){
var item = ref.item;
return [(item.data.timestamp)?_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.formatDate(item.data.timestamp.toDate()))+" ")]):_vm._e()]}},{key:"item.data.privado",fn:function(ref){
var item = ref.item;
return [_c('center',[_vm._v(_vm._s(item.data.privado ? "Privado" : "Compartido"))])]}},{key:"item.data.oculta",fn:function(ref){
var item = ref.item;
return [_c('center',[_vm._v(_vm._s(!item.data.oculta ? "✅" : "❌"))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }