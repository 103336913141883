<template>
  <div>
    <h1 class="mb-3">Preparar link para nueva calificación:</h1>

    <v-row>
      <v-col>
        Usuario:
        <v-text-field v-model="usuarioEmail" outlined />
        <v-btn v-if="!showUsuarios" @click="showUsuarios = true"
          >Mostrar correos registrados</v-btn
        >

        <v-autocomplete
          v-else
          v-model="usuarioEmail"
          :items="usuarios"
          outlined
          label="Emails registrados"
          :value="usuarioEmail"
        ></v-autocomplete>
      </v-col>
      <v-col>
        Experiencia:
        <v-select
          v-model="experienciaID"
          :items="experienciasFormated"
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <h3 class="my-10">
      Link: <a class="deep-purple--text " :href="link">{{ link }}</a>
    </h3>

    <div class="calificaciones">
      Todas las calificaciones:
      <v-data-table
        :headers="headers"
        :items="calificaciones"
        :items-per-page="20"
        class="elevation-1"
      >
        <template v-slot:item.date="{ item }">
          {{ item.date ? convertToDate(item.date.toDate()) : "" }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "CalificacionesGenerator",
  data() {
    return {
      showUsuarios: false,
      usuarioEmail: "",
      experienciaID: "",
      usuarios: [],
      headers: [
        { text: "Experiencia", value: "idExperiencia" },
        { text: "Usuario", value: "usuario" },
        { text: "Comentario", value: "comentario" },
        { text: "Fecha", value: "date" },
        { text: "Calificacion", value: "rating" },
      ],
      calificaciones: [],
    };
  },
  methods: {
    convertToDate(date) {
      return moment(date).format("LL");
    },
  },
  computed: {
    ...mapState("Experiencias", ["experiencias"]),

    headers() {
      return Object.keys(this.calificaciones[0]);
    },

    experienciasFormated() {
      return this.experiencias.map((e) => {
        return {
          text:
            e.data.destino + (e.data.privado ? " - Privado" : " - Compartido"),
          value: e.id,
        };
      });
    },

    link() {
      const { usuarioEmail, experienciaID } = this;
      const localhost = window.location.href.includes("localhost");
      let url = "";
      if (localhost) url = `http://localhost:8080/calificaciones`;
      else url = `https://hummingbird.cl/calificaciones`;

      const { data } =
        this.experiencias?.find((e) => e.id === experienciaID) || {};

      const destino = data?.destino;
      return `${url}/${experienciaID}?usuario=${usuarioEmail}&destino=${destino ||
        ""}`;
    },
  },

  created() {
    firebase
      .firestore()
      .collection("usuarios")
      .onSnapshot((snapshot) => {
        const usuarios = [];
        snapshot.forEach((doc) => {
          usuarios.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        this.usuarios = usuarios.map((u) => u.data.email);
      });

    firebase
      .firestore()
      .collection("comentarios")
      .onSnapshot((snapshot) => {
        const calificaciones = [];
        snapshot.forEach((doc) => {
          calificaciones.push(doc.data());
        });
        this.calificaciones = calificaciones;
      });
  },
};
</script>

<style></style>
