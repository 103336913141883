var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":function($event){_vm.abierto = true}}},'div',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialogoListaPasajeros),callback:function ($$v) {_vm.dialogoListaPasajeros=$$v},expression:"dialogoListaPasajeros"}},[_c('v-sheet',{staticClass:"py-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pasajeros,"dense":""},scopedSlots:_vm._u([{key:"item.rut",fn:function(ref){
var item = ref.item;
return [(item.documento === 'RUT')?_c('p',[_vm._v(" "+_vm._s(item.rut)+" ")]):_c('p',[_vm._v(_vm._s(item.pasaporte))])]}},{key:"item.equipaje",fn:function(ref){
var item = ref.item;
return [_c('ul',[(item.equipaje.mano)?_c('li',[_vm._v(" "+_vm._s(item.equipaje.mano ? "Mano" : "")+" ")]):_vm._e(),(item.equipaje.cabina)?_c('li',[_vm._v(" "+_vm._s(item.equipaje.cabina ? "Cabina" : "")+" ")]):_vm._e(),(item.equipaje.extra)?_c('li',[_vm._v(" "+_vm._s(item.equipaje.extra ? "Extra" : "")+" ")]):_vm._e()])]}},{key:"item.salud",fn:function(ref){
var item = ref.item;
return [(item.salud)?_c('ul',[(item.salud.sintomas)?_c('li',[_vm._v(" "+_vm._s(item.salud.sintomas ? "Presenta sintomas" : "")+" ")]):_vm._e(),(item.salud.contactoEstrecho)?_c('li',[_vm._v(" "+_vm._s(item.salud.contactoEstrecho ? "Contacto estrecho" : "")+" ")]):_vm._e(),(item.salud.pcrPositivo)?_c('li',[_vm._v(" "+_vm._s(item.salud.pcrPositivo ? "PCR positivo 2 semanas" : "")+" ")]):_vm._e(),(item.salud.haSalidoDelPais)?_c('li',[_vm._v(" "+_vm._s(item.salud.haSalidoDelPais ? "Ha salido del país" : "")+" ")]):_vm._e()]):_vm._e()]}}])}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"width":"80%","rounded":"","dark":""},on:{"click":function($event){_vm.dialogoListaPasajeros = false}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }