<template>
  <div>
    <v-text-field
      v-model="input"
      label="Busqueda de experiencias por destino"
      color="deep-purple"
    />
    <h3>Experiencias</h3>
    <v-data-table
      :headers="headers"
      dense
      :items="experienciasFiltradas"
      :sort-by="['data.timestamp']"
      :sort-desc="[true]"
    >
      <template v-slot:item.data.checkIn="{ item }">
        <ListaPasajeros v-if="item.data.checkIn" :checkIns="item.data.checkIn">
          <v-btn :color="colors.mainPurple" dark small rounded>Ver datos</v-btn>
        </ListaPasajeros>
        <p v-else>No hay pasajeros</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editExperiencia(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon small @click="copyExperiencia(item)">
          mdi-content-copy
        </v-icon>
      </template>

      <template v-slot:item.data.timestamp="{ item }">
        <p class="text-capitalize" v-if="item.data.timestamp">
          {{ formatDate(item.data.timestamp.toDate()) }}
        </p>
      </template>

      <template v-slot:item.data.privado="{ item }">
        <center>{{ item.data.privado ? "Privado" : "Compartido" }}</center>
      </template>
      <template v-slot:item.data.oculta="{ item }">
        <center>{{ !item.data.oculta ? "✅" : "❌" }}</center>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ListaPasajeros from "@/components/Admin/ListaPasajeros";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Viajes-Admin",
  components: { ListaPasajeros },

  data() {
    return {
      headers: [
        { text: "ID", value: "id", divider: true },
        // { text: "Fecha", value: "data.fecha", align: "start", divider: true },
        // { text: "Hora", value: "data.horaDespegue", divider: true },
        // { text: "Origen", value: "data.origen", divider: true },
        { text: "Zona", value: "data.zona", divider: true },
        { text: "Destino", value: "data.destino", divider: true },
        { text: "Tarifa", value: "data.privado", divider: true },
        { text: "Capacidad", value: "data.capacidad", divider: true },
        { text: "Tiempo", value: "data.tiempoDeVuelo", divider: true },
        { text: "Operador", value: "data.operador.nombre", divider: true },
        { text: "Visible", value: "data.oculta", divider: true },
        // { text: "Estado", value: "data.status", divider: true },
        { text: "Última Modificación", value: "data.timestamp", divider: true },
        // {
        //   text: "Precio por Persona(USD)",
        //   value: "data.precioEnDolares",
        //   divider: true,
        // },
        // { text: "Ventas", value: "data.ventas", divider: true },
        // { text: "Check-in", value: "data.checkIn", divider: true },
        { text: " ", value: "actions" },
      ],
      input: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Vuelos", ["vuelos"]),
    ...mapState("Experiencias", ["experiencias"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    experienciasFiltradas() {
      const { experiencias, input } = this;
      return input
        ? experiencias.filter((e) => {
            return e.data.destino.toLowerCase().includes(input.toLowerCase());
          })
        : experiencias;
    },
  },
  methods: {
    ...mapActions("Experiencias", ["deleteExperiencia", "copias_Experiencia"]),
    editExperiencia(experiencia) {
      const { id } = experiencia;
      this.$router.push(`/admin/editar-experiencia/${id}`);
    },
    deleteItem({ id }) {
      try {
        confirm("¿Seguro que quieres eliminar este viaje?")
          ? this.deleteExperiencia(id)
          : false;
      } catch (e) {
        console.log(e);
      }
    },
    copyExperiencia({ id }) {
      try {
        confirm("¿Seguro que quieres copiar este viaje?")
          ? this.copias_Experiencia(id)
          : false;
      } catch (e) {
        console.log(e);
      }
    },
    formatDate(date) {
      return moment(date)
        .locale("es")
        .calendar();
    },
  },
  title() {
    return `Viajes - HummingBird`;
  },
};
</script>

<style lang="scss" scoped></style>
